import {IncomingMessage} from 'http'
import {NextApiRequest} from 'next'
import {NextRequest} from 'next/server'

const isNextRequest = (req: NextApiRequest | NextRequest | IncomingMessage): req is NextRequest => {
  return (req as NextRequest)?.headers?.get !== undefined
}

export function getNormalizedHostname(hostname: string | string[]) {
  if (!hostname) return null
  if (Array.isArray(hostname)) {
    return getNormalizedHostname(hostname[0])
  }

  return hostname.split(':')[0].replace('www.', '')
}

export default function getNormalizedHostnameFromReq(req: NextRequest | NextApiRequest | IncomingMessage) {
  // Get hostname (e.g. mywebsite.com or website.local)
  const hostname = isNextRequest(req) ? req.headers.get('host') : req.headers['host']
  return getNormalizedHostname(hostname)
}
