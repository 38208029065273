import React, {useState} from 'react'
import {fireEvent} from 'react-app-events'
import useOnEvent from 'react-app-events/lib/useOnEvent'
import SessionContext from '@helpers/auth/SessionContext'
import useIsAnalyticsV2Avail from '@page-components/AnalyticsV2/hooks/useIsAnalyticsV2Available'

import getJWTSavedData from './apollo/getJWTSavedData'

export default function SessionContextProvider(props) {
  const jwtData = getJWTSavedData()
  const isAnalyticsV2Avail = useIsAnalyticsV2Avail()
  const [session, setSession] = useState(jwtData)

  useOnEvent('saveSession', async newSession => {
    setSession(newSession)

    if (newSession?.userId) {
      if (isAnalyticsV2Avail) {
        fireEvent('updateAnalyticsUserId', newSession?.userId)
      } else {
        fireEvent('saveAmplitudeSession', newSession || {})
      }
    }
  })

  return <SessionContext.Provider value={session || {}}>{props.children}</SessionContext.Provider>
}
