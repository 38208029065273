import gql from 'graphql-tag'

import layoutFragment from '../layoutFragment.main'
import localeFragment from '../localeFragment.main'

export default gql`
  fragment layout on Query {
    website(websiteId: $websiteId) {
      _id
      appsCodes
      shouldUseInitialAddressMode
      name
      description
      fontsCss
      justoBrand
      authOnlyJustoUsers
      businessType
      country {
        name
        canSendDevolutionDataEmail
      }
      navbarConfiguration {
        importantMessageTableText
      }

      ...websiteLayout
      ...websiteLocale
    }

    design: sectionConfiguration(section: "design", websiteId: $websiteId) {
      _id
      design {
        primaryColor
        customCss
        backgroundColor
        productCardDesign
        hideProductsDiscountBadge
      }
    }

    navbar: sectionConfiguration(section: "navbar", websiteId: $websiteId) {
      _id
      navbar {
        backgroundColor
        textColor
        buttonColor
        upperBarColor
        hintColor
      }
    }

    seo: sectionConfiguration(section: "seo", websiteId: $websiteId) {
      _id
      seo {
        title
        description
        image {
          _id
          url
        }
        favicon {
          _id
          url: resizedURL(width: 144, height: 144, resizeMode: "contain", format: "png")
        }
      }
    }

    integrations: sectionConfiguration(section: "integrations", websiteId: $websiteId) {
      _id
      integrations {
        jivoChatId
        facebookChatPageId
        supportWhatsappNumber
        whatsappBusinessNumber
        googleAnalyticsId
        googleAnalyticsV4Id
        intercomId
        facebookPixelId
        tawkToId
        tagManagerId
        zendeskId
        logRocketId
        indigitallWebPush
        sendpulse {
          apiKey
        }
      }
      justoAdsPixelId
      additionalPixels
    }
    config: sectionConfiguration(section: "app", websiteId: $websiteId) {
      app {
        playStoreURL
        appStoreURL
        downloadAppBarMessage
      }
    }
  }
  ${layoutFragment}
  ${localeFragment}
`
